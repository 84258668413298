package kotlinx.html

import kotlinx.html.*
import kotlinx.html.impl.*
import kotlinx.html.attributes.*

/*******************************************************************************
    DO NOT EDIT
    This file was generated by module generate
*******************************************************************************/

@Suppress("unused")
open class RP(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("rp", consumer, initialAttributes, null, true, false), HtmlInlineTag {

}

@Suppress("unused")
open class RT(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("rt", consumer, initialAttributes, null, true, false), HtmlInlineTag {

}

@Suppress("unused")
open class RUBY(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("ruby", consumer, initialAttributes, null, true, false), HtmlBlockInlineTag {

}
fun RUBY.rt(classes : String? = null, block : RT.() -> Unit = {}) : Unit = RT(attributesMapOf("class", classes), consumer).visit(block)

fun RUBY.rp(classes : String? = null, block : RP.() -> Unit = {}) : Unit = RP(attributesMapOf("class", classes), consumer).visit(block)

val RUBY.asFlowContent : FlowContent
    get()  = this

val RUBY.asPhrasingContent : PhrasingContent
    get()  = this


