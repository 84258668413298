package kotlinx.html

import kotlinx.html.*
import kotlinx.html.impl.*
import kotlinx.html.attributes.*

/*******************************************************************************
    DO NOT EDIT
    This file was generated by module generate
*******************************************************************************/

interface FlowOrHeadingContent : Tag {
}

interface FlowOrMetaDataContent : FlowOrPhrasingOrMetaDataContent, Tag {
}

interface FlowOrInteractiveContent : FlowOrInteractiveOrPhrasingContent, Tag {
}

interface FlowOrPhrasingContent : FlowOrInteractiveOrPhrasingContent, FlowOrPhrasingOrMetaDataContent, Tag {
}

interface FlowOrPhrasingOrMetaDataContent : Tag {
}

interface SectioningOrFlowContent : Tag {
}

interface FlowOrInteractiveOrPhrasingContent : Tag {
}



fun FlowOrHeadingContent.h1(classes : String? = null, block : H1.() -> Unit = {}) : Unit = H1(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrHeadingContent.h2(classes : String? = null, block : H2.() -> Unit = {}) : Unit = H2(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrHeadingContent.h3(classes : String? = null, block : H3.() -> Unit = {}) : Unit = H3(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrHeadingContent.h4(classes : String? = null, block : H4.() -> Unit = {}) : Unit = H4(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrHeadingContent.h5(classes : String? = null, block : H5.() -> Unit = {}) : Unit = H5(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrHeadingContent.h6(classes : String? = null, block : H6.() -> Unit = {}) : Unit = H6(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrHeadingContent.hGroup(classes : String? = null, block : HGROUP.() -> Unit = {}) : Unit = HGROUP(attributesMapOf("class", classes), consumer).visit(block)


fun FlowOrMetaDataContent.style(type : String? = null, block : STYLE.() -> Unit = {}) : Unit = STYLE(attributesMapOf("type", type), consumer).visit(block)
fun FlowOrMetaDataContent.style(type : String? = null, content : String = "") : Unit = STYLE(attributesMapOf("type", type), consumer).visit({+content})


fun FlowOrInteractiveContent.details(classes : String? = null, block : DETAILS.() -> Unit = {}) : Unit = DETAILS(attributesMapOf("class", classes), consumer).visit(block)


fun FlowOrPhrasingContent.abbr(classes : String? = null, block : ABBR.() -> Unit = {}) : Unit = ABBR(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.area(shape : AreaShape? = null, alt : String? = null, classes : String? = null, block : AREA.() -> Unit = {}) : Unit = AREA(attributesMapOf("Shape", shape?.enumEncode(),"alt", alt,"class", classes), consumer).visit(block)
fun FlowOrPhrasingContent.rectArea(alt : String? = null, classes : String? = null, block : AREA.() -> Unit = {}) : Unit = AREA(attributesMapOf("Shape", AreaShape.rect.realValue,"alt", alt,"class", classes), consumer).visit(block)
fun FlowOrPhrasingContent.circleArea(alt : String? = null, classes : String? = null, block : AREA.() -> Unit = {}) : Unit = AREA(attributesMapOf("Shape", AreaShape.circle.realValue,"alt", alt,"class", classes), consumer).visit(block)
fun FlowOrPhrasingContent.polyArea(alt : String? = null, classes : String? = null, block : AREA.() -> Unit = {}) : Unit = AREA(attributesMapOf("Shape", AreaShape.poly.realValue,"alt", alt,"class", classes), consumer).visit(block)
fun FlowOrPhrasingContent.defaultArea(alt : String? = null, classes : String? = null, block : AREA.() -> Unit = {}) : Unit = AREA(attributesMapOf("Shape", AreaShape.default.realValue,"alt", alt,"class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.b(classes : String? = null, block : B.() -> Unit = {}) : Unit = B(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.bdi(classes : String? = null, block : BDI.() -> Unit = {}) : Unit = BDI(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.bdo(classes : String? = null, block : BDO.() -> Unit = {}) : Unit = BDO(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.br(classes : String? = null, block : BR.() -> Unit = {}) : Unit = BR(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.canvas(classes : String? = null, block : CANVAS.() -> Unit = {}) : Unit = CANVAS(attributesMapOf("class", classes), consumer).visit(block)
fun FlowOrPhrasingContent.canvas(classes : String? = null, content : String = "") : Unit = CANVAS(attributesMapOf("class", classes), consumer).visit({+content})

fun FlowOrPhrasingContent.cite(classes : String? = null, block : CITE.() -> Unit = {}) : Unit = CITE(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.code(classes : String? = null, block : CODE.() -> Unit = {}) : Unit = CODE(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.dataList(classes : String? = null, block : DATALIST.() -> Unit = {}) : Unit = DATALIST(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.del(classes : String? = null, block : DEL.() -> Unit = {}) : Unit = DEL(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.dfn(classes : String? = null, block : DFN.() -> Unit = {}) : Unit = DFN(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.em(classes : String? = null, block : EM.() -> Unit = {}) : Unit = EM(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.i(classes : String? = null, block : I.() -> Unit = {}) : Unit = I(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.ins(classes : String? = null, block : INS.() -> Unit = {}) : Unit = INS(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.kbd(classes : String? = null, block : KBD.() -> Unit = {}) : Unit = KBD(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.map(name : String? = null, classes : String? = null, block : MAP.() -> Unit = {}) : Unit = MAP(attributesMapOf("name", name,"class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.mark(classes : String? = null, block : MARK.() -> Unit = {}) : Unit = MARK(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.math(classes : String? = null, block : MATH.() -> Unit = {}) : Unit = MATH(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.meter(classes : String? = null, block : METER.() -> Unit = {}) : Unit = METER(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.output(classes : String? = null, block : OUTPUT.() -> Unit = {}) : Unit = OUTPUT(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.progress(classes : String? = null, block : PROGRESS.() -> Unit = {}) : Unit = PROGRESS(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.q(classes : String? = null, block : Q.() -> Unit = {}) : Unit = Q(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.ruby(classes : String? = null, block : RUBY.() -> Unit = {}) : Unit = RUBY(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.samp(classes : String? = null, block : SAMP.() -> Unit = {}) : Unit = SAMP(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.small(classes : String? = null, block : SMALL.() -> Unit = {}) : Unit = SMALL(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.span(classes : String? = null, block : SPAN.() -> Unit = {}) : Unit = SPAN(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.strong(classes : String? = null, block : STRONG.() -> Unit = {}) : Unit = STRONG(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.sub(classes : String? = null, block : SUB.() -> Unit = {}) : Unit = SUB(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.sup(classes : String? = null, block : SUP.() -> Unit = {}) : Unit = SUP(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.svg(classes : String? = null, block : SVG.() -> Unit = {}) : Unit = SVG(attributesMapOf("class", classes), consumer).visit(block)
fun FlowOrPhrasingContent.svg(classes : String? = null, content : String = "") : Unit = SVG(attributesMapOf("class", classes), consumer).visit({+content})

fun FlowOrPhrasingContent.time(classes : String? = null, block : TIME.() -> Unit = {}) : Unit = TIME(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingContent.var_(classes : String? = null, block : VAR_.() -> Unit = {}) : Unit = VAR_(attributesMapOf("class", classes), consumer).visit(block)


fun FlowOrPhrasingOrMetaDataContent.command(type : CommandType? = null, classes : String? = null, block : COMMAND.() -> Unit = {}) : Unit = COMMAND(attributesMapOf("type", type?.enumEncode(),"class", classes), consumer).visit(block)
fun FlowOrPhrasingOrMetaDataContent.commandCommand(classes : String? = null, block : COMMAND.() -> Unit = {}) : Unit = COMMAND(attributesMapOf("type", CommandType.command.realValue,"class", classes), consumer).visit(block)
fun FlowOrPhrasingOrMetaDataContent.checkBoxCommand(classes : String? = null, block : COMMAND.() -> Unit = {}) : Unit = COMMAND(attributesMapOf("type", CommandType.checkBox.realValue,"class", classes), consumer).visit(block)
fun FlowOrPhrasingOrMetaDataContent.radioCommand(classes : String? = null, block : COMMAND.() -> Unit = {}) : Unit = COMMAND(attributesMapOf("type", CommandType.radio.realValue,"class", classes), consumer).visit(block)

fun FlowOrPhrasingOrMetaDataContent.link(href : String? = null, rel : String? = null, type : String? = null, block : LINK.() -> Unit = {}) : Unit = LINK(attributesMapOf("href", href,"rel", rel,"type", type), consumer).visit(block)

fun FlowOrPhrasingOrMetaDataContent.meta(name : String? = null, content : String? = null, block : META.() -> Unit = {}) : Unit = META(attributesMapOf("name", name,"content", content), consumer).visit(block)

fun FlowOrPhrasingOrMetaDataContent.noScript(classes : String? = null, block : NOSCRIPT.() -> Unit = {}) : Unit = NOSCRIPT(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrPhrasingOrMetaDataContent.script(type : String? = null, src : String? = null, block : SCRIPT.() -> Unit = {}) : Unit = SCRIPT(attributesMapOf("type", type,"src", src), consumer).visit(block)


fun SectioningOrFlowContent.article(classes : String? = null, block : ARTICLE.() -> Unit = {}) : Unit = ARTICLE(attributesMapOf("class", classes), consumer).visit(block)

fun SectioningOrFlowContent.aside(classes : String? = null, block : ASIDE.() -> Unit = {}) : Unit = ASIDE(attributesMapOf("class", classes), consumer).visit(block)

fun SectioningOrFlowContent.nav(classes : String? = null, block : NAV.() -> Unit = {}) : Unit = NAV(attributesMapOf("class", classes), consumer).visit(block)

fun SectioningOrFlowContent.section(classes : String? = null, block : SECTION.() -> Unit = {}) : Unit = SECTION(attributesMapOf("class", classes), consumer).visit(block)


fun FlowOrInteractiveOrPhrasingContent.a(href : String? = null, target : String? = null, classes : String? = null, block : A.() -> Unit = {}) : Unit = A(attributesMapOf("href", href,"target", target,"class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.audio(classes : String? = null, block : AUDIO.() -> Unit = {}) : Unit = AUDIO(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.button(formEncType : ButtonFormEncType? = null, formMethod : ButtonFormMethod? = null, type : ButtonType? = null, classes : String? = null, block : BUTTON.() -> Unit = {}) : Unit = BUTTON(attributesMapOf("formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"type", type?.enumEncode(),"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.getButton(formEncType : ButtonFormEncType? = null, type : ButtonType? = null, classes : String? = null, block : BUTTON.() -> Unit = {}) : Unit = BUTTON(attributesMapOf("formenctype", formEncType?.enumEncode(),"formmethod", ButtonFormMethod.get.realValue,"type", type?.enumEncode(),"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.postButton(formEncType : ButtonFormEncType? = null, type : ButtonType? = null, classes : String? = null, block : BUTTON.() -> Unit = {}) : Unit = BUTTON(attributesMapOf("formenctype", formEncType?.enumEncode(),"formmethod", ButtonFormMethod.post.realValue,"type", type?.enumEncode(),"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.putButton(formEncType : ButtonFormEncType? = null, type : ButtonType? = null, classes : String? = null, block : BUTTON.() -> Unit = {}) : Unit = BUTTON(attributesMapOf("formenctype", formEncType?.enumEncode(),"formmethod", ButtonFormMethod.put.realValue,"type", type?.enumEncode(),"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.deleteButton(formEncType : ButtonFormEncType? = null, type : ButtonType? = null, classes : String? = null, block : BUTTON.() -> Unit = {}) : Unit = BUTTON(attributesMapOf("formenctype", formEncType?.enumEncode(),"formmethod", ButtonFormMethod.delete.realValue,"type", type?.enumEncode(),"class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.embed(classes : String? = null, block : EMBED.() -> Unit = {}) : Unit = EMBED(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.iframe(sandbox : IframeSandbox? = null, classes : String? = null, block : IFRAME.() -> Unit = {}) : Unit = IFRAME(attributesMapOf("sandbox", sandbox?.enumEncode(),"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.iframe(sandbox : IframeSandbox? = null, classes : String? = null, content : String = "") : Unit = IFRAME(attributesMapOf("sandbox", sandbox?.enumEncode(),"class", classes), consumer).visit({+content})
fun FlowOrInteractiveOrPhrasingContent.allowSameOriginIframe(classes : String? = null, block : IFRAME.() -> Unit = {}) : Unit = IFRAME(attributesMapOf("sandbox", IframeSandbox.allowSameOrigin.realValue,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.allowFormSIframe(classes : String? = null, block : IFRAME.() -> Unit = {}) : Unit = IFRAME(attributesMapOf("sandbox", IframeSandbox.allowFormS.realValue,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.allowScriptsIframe(classes : String? = null, block : IFRAME.() -> Unit = {}) : Unit = IFRAME(attributesMapOf("sandbox", IframeSandbox.allowScripts.realValue,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.allowSameOriginIframe(classes : String? = null, content : String = "") : Unit = IFRAME(attributesMapOf("sandbox", IframeSandbox.allowSameOrigin.realValue,"class", classes), consumer).visit({+content})
fun FlowOrInteractiveOrPhrasingContent.allowFormSIframe(classes : String? = null, content : String = "") : Unit = IFRAME(attributesMapOf("sandbox", IframeSandbox.allowFormS.realValue,"class", classes), consumer).visit({+content})
fun FlowOrInteractiveOrPhrasingContent.allowScriptsIframe(classes : String? = null, content : String = "") : Unit = IFRAME(attributesMapOf("sandbox", IframeSandbox.allowScripts.realValue,"class", classes), consumer).visit({+content})

fun FlowOrInteractiveOrPhrasingContent.img(alt : String? = null, src : String? = null, classes : String? = null, block : IMG.() -> Unit = {}) : Unit = IMG(attributesMapOf("alt", alt,"src", src,"class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.input(type : InputType? = null, formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", type?.enumEncode(),"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.buttonInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.button.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.checkBoxInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.checkBox.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.colorInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.color.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.dateInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.date.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.dateTimeInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.dateTime.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.dateTimeLocalInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.dateTimeLocal.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.emailInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.email.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.fileInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.file.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.hiddenInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.hidden.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.imageInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.image.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.monthInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.month.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.numberInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.number.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.passwordInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.password.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.radioInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.radio.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.rangeInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.range.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.resetInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.reset.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.searchInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.search.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.submitInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.submit.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.textInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.text.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.telInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.tel.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.timeInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.time.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.urlInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.url.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.weekInput(formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : Unit = INPUT(attributesMapOf("type", InputType.week.realValue,"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.keyGen(keyType : KeyGenKeyType? = null, classes : String? = null, block : KEYGEN.() -> Unit = {}) : Unit = KEYGEN(attributesMapOf("keytype", keyType?.enumEncode(),"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.rsaKeyGen(classes : String? = null, block : KEYGEN.() -> Unit = {}) : Unit = KEYGEN(attributesMapOf("keytype", KeyGenKeyType.rsa.realValue,"class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.label(classes : String? = null, block : LABEL.() -> Unit = {}) : Unit = LABEL(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.object_(classes : String? = null, block : OBJECT_.() -> Unit = {}) : Unit = OBJECT_(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.select(classes : String? = null, block : SELECT.() -> Unit = {}) : Unit = SELECT(attributesMapOf("class", classes), consumer).visit(block)

fun FlowOrInteractiveOrPhrasingContent.textArea(rows : String? = null, cols : String? = null, wrap : TextAreaWrap? = null, classes : String? = null, block : TEXTAREA.() -> Unit = {}) : Unit = TEXTAREA(attributesMapOf("rows", rows,"cols", cols,"wrap", wrap?.enumEncode(),"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.textArea(rows : String? = null, cols : String? = null, wrap : TextAreaWrap? = null, classes : String? = null, content : String = "") : Unit = TEXTAREA(attributesMapOf("rows", rows,"cols", cols,"wrap", wrap?.enumEncode(),"class", classes), consumer).visit({+content})
fun FlowOrInteractiveOrPhrasingContent.hardTextArea(rows : String? = null, cols : String? = null, classes : String? = null, block : TEXTAREA.() -> Unit = {}) : Unit = TEXTAREA(attributesMapOf("rows", rows,"cols", cols,"wrap", TextAreaWrap.hard.realValue,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.softTextArea(rows : String? = null, cols : String? = null, classes : String? = null, block : TEXTAREA.() -> Unit = {}) : Unit = TEXTAREA(attributesMapOf("rows", rows,"cols", cols,"wrap", TextAreaWrap.soft.realValue,"class", classes), consumer).visit(block)
fun FlowOrInteractiveOrPhrasingContent.hardTextArea(rows : String? = null, cols : String? = null, classes : String? = null, content : String = "") : Unit = TEXTAREA(attributesMapOf("rows", rows,"cols", cols,"wrap", TextAreaWrap.hard.realValue,"class", classes), consumer).visit({+content})
fun FlowOrInteractiveOrPhrasingContent.softTextArea(rows : String? = null, cols : String? = null, classes : String? = null, content : String = "") : Unit = TEXTAREA(attributesMapOf("rows", rows,"cols", cols,"wrap", TextAreaWrap.soft.realValue,"class", classes), consumer).visit({+content})

fun FlowOrInteractiveOrPhrasingContent.video(classes : String? = null, block : VIDEO.() -> Unit = {}) : Unit = VIDEO(attributesMapOf("class", classes), consumer).visit(block)


