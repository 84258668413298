package kotlinx.html.js

import kotlinx.html.*
import kotlinx.html.impl.*
import kotlinx.html.attributes.*
import org.w3c.dom.*

/*******************************************************************************
    DO NOT EDIT
    This file was generated by module generate
*******************************************************************************/

public fun TagConsumer<HTMLElement>.a(href : String? = null, target : String? = null, classes : String? = null, block : A.() -> Unit = {}) : HTMLAnchorElement = A(attributesMapOf("href", href,"target", target,"class", classes), this).visitAndFinalize(this, block) as HTMLAnchorElement

public fun TagConsumer<HTMLElement>.abbr(classes : String? = null, block : ABBR.() -> Unit = {}) : HTMLElement = ABBR(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.address(classes : String? = null, block : ADDRESS.() -> Unit = {}) : HTMLElement = ADDRESS(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.area(shape : AreaShape? = null, alt : String? = null, classes : String? = null, block : AREA.() -> Unit = {}) : HTMLAreaElement = AREA(attributesMapOf("Shape", shape?.enumEncode(),"alt", alt,"class", classes), this).visitAndFinalize(this, block) as HTMLAreaElement

public fun TagConsumer<HTMLElement>.article(classes : String? = null, block : ARTICLE.() -> Unit = {}) : HTMLElement = ARTICLE(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.aside(classes : String? = null, block : ASIDE.() -> Unit = {}) : HTMLElement = ASIDE(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.audio(classes : String? = null, block : AUDIO.() -> Unit = {}) : HTMLAudioElement = AUDIO(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLAudioElement

public fun TagConsumer<HTMLElement>.b(classes : String? = null, block : B.() -> Unit = {}) : HTMLElement = B(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.base(classes : String? = null, block : BASE.() -> Unit = {}) : HTMLBaseElement = BASE(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLBaseElement

public fun TagConsumer<HTMLElement>.bdi(classes : String? = null, block : BDI.() -> Unit = {}) : HTMLElement = BDI(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.bdo(classes : String? = null, block : BDO.() -> Unit = {}) : HTMLElement = BDO(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.blockQuote(classes : String? = null, block : BLOCKQUOTE.() -> Unit = {}) : HTMLElement = BLOCKQUOTE(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.body(classes : String? = null, block : BODY.() -> Unit = {}) : HTMLBodyElement = BODY(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLBodyElement

public fun TagConsumer<HTMLElement>.br(classes : String? = null, block : BR.() -> Unit = {}) : HTMLBRElement = BR(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLBRElement

public fun TagConsumer<HTMLElement>.button(formEncType : ButtonFormEncType? = null, formMethod : ButtonFormMethod? = null, type : ButtonType? = null, classes : String? = null, block : BUTTON.() -> Unit = {}) : HTMLButtonElement = BUTTON(attributesMapOf("formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"type", type?.enumEncode(),"class", classes), this).visitAndFinalize(this, block) as HTMLButtonElement

public fun TagConsumer<HTMLElement>.canvas(classes : String? = null, content : String = "") : HTMLCanvasElement = CANVAS(attributesMapOf("class", classes), this).visitAndFinalize(this, {+content}) as HTMLCanvasElement
public fun TagConsumer<HTMLElement>.canvas(classes : String? = null, block : CANVAS.() -> Unit = {}) : HTMLCanvasElement = CANVAS(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLCanvasElement

public fun TagConsumer<HTMLElement>.caption(classes : String? = null, block : CAPTION.() -> Unit = {}) : HTMLElement = CAPTION(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.cite(classes : String? = null, block : CITE.() -> Unit = {}) : HTMLElement = CITE(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.code(classes : String? = null, block : CODE.() -> Unit = {}) : HTMLElement = CODE(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.col(classes : String? = null, block : COL.() -> Unit = {}) : HTMLElement = COL(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.colGroup(classes : String? = null, block : COLGROUP.() -> Unit = {}) : HTMLElement = COLGROUP(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.command(type : CommandType? = null, classes : String? = null, block : COMMAND.() -> Unit = {}) : HTMLElement = COMMAND(attributesMapOf("type", type?.enumEncode(),"class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.dataList(classes : String? = null, block : DATALIST.() -> Unit = {}) : HTMLDataListElement = DATALIST(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLDataListElement

public fun TagConsumer<HTMLElement>.dd(classes : String? = null, block : DD.() -> Unit = {}) : HTMLElement = DD(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.del(classes : String? = null, block : DEL.() -> Unit = {}) : HTMLElement = DEL(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.details(classes : String? = null, block : DETAILS.() -> Unit = {}) : HTMLDetailsElement = DETAILS(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLDetailsElement

public fun TagConsumer<HTMLElement>.dfn(classes : String? = null, block : DFN.() -> Unit = {}) : HTMLElement = DFN(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.dialog(classes : String? = null, block : DIALOG.() -> Unit = {}) : HTMLDialogElement = DIALOG(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLDialogElement

public fun TagConsumer<HTMLElement>.div(classes : String? = null, block : DIV.() -> Unit = {}) : HTMLDivElement = DIV(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLDivElement

public fun TagConsumer<HTMLElement>.dl(classes : String? = null, block : DL.() -> Unit = {}) : HTMLElement = DL(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.dt(classes : String? = null, block : DT.() -> Unit = {}) : HTMLElement = DT(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.em(classes : String? = null, block : EM.() -> Unit = {}) : HTMLElement = EM(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.embed(classes : String? = null, block : EMBED.() -> Unit = {}) : HTMLEmbedElement = EMBED(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLEmbedElement

public fun TagConsumer<HTMLElement>.fieldSet(classes : String? = null, block : FIELDSET.() -> Unit = {}) : HTMLFieldSetElement = FIELDSET(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLFieldSetElement

public fun TagConsumer<HTMLElement>.figcaption(classes : String? = null, block : FIGCAPTION.() -> Unit = {}) : HTMLElement = FIGCAPTION(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.figure(classes : String? = null, block : FIGURE.() -> Unit = {}) : HTMLElement = FIGURE(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.footer(classes : String? = null, block : FOOTER.() -> Unit = {}) : HTMLElement = FOOTER(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.form(action : String? = null, encType : FormEncType? = null, method : FormMethod? = null, classes : String? = null, block : FORM.() -> Unit = {}) : HTMLFormElement = FORM(attributesMapOf("action", action,"enctype", encType?.enumEncode(),"method", method?.enumEncode(),"class", classes), this).visitAndFinalize(this, block) as HTMLFormElement

public fun TagConsumer<HTMLElement>.h1(classes : String? = null, block : H1.() -> Unit = {}) : HTMLHeadingElement = H1(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLHeadingElement

public fun TagConsumer<HTMLElement>.h2(classes : String? = null, block : H2.() -> Unit = {}) : HTMLHeadingElement = H2(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLHeadingElement

public fun TagConsumer<HTMLElement>.h3(classes : String? = null, block : H3.() -> Unit = {}) : HTMLHeadingElement = H3(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLHeadingElement

public fun TagConsumer<HTMLElement>.h4(classes : String? = null, block : H4.() -> Unit = {}) : HTMLHeadingElement = H4(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLHeadingElement

public fun TagConsumer<HTMLElement>.h5(classes : String? = null, block : H5.() -> Unit = {}) : HTMLHeadingElement = H5(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLHeadingElement

public fun TagConsumer<HTMLElement>.h6(classes : String? = null, block : H6.() -> Unit = {}) : HTMLHeadingElement = H6(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLHeadingElement

public fun TagConsumer<HTMLElement>.head(block : HEAD.() -> Unit = {}) : HTMLHeadElement = HEAD(emptyMap, this).visitAndFinalize(this, block) as HTMLHeadElement

public fun TagConsumer<HTMLElement>.header(classes : String? = null, block : HEADER.() -> Unit = {}) : HTMLElement = HEADER(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.hGroup(classes : String? = null, block : HGROUP.() -> Unit = {}) : HTMLElement = HGROUP(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.hr(classes : String? = null, block : HR.() -> Unit = {}) : HTMLHRElement = HR(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLHRElement

public fun TagConsumer<HTMLElement>.html(block : HTML.() -> Unit = {}) : HTMLHtmlElement = HTML(emptyMap, this).visitAndFinalize(this, block) as HTMLHtmlElement

public fun TagConsumer<HTMLElement>.i(classes : String? = null, block : I.() -> Unit = {}) : HTMLElement = I(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.iframe(sandbox : IframeSandbox? = null, classes : String? = null, content : String = "") : HTMLElement = IFRAME(attributesMapOf("sandbox", sandbox?.enumEncode(),"class", classes), this).visitAndFinalize(this, {+content})
public fun TagConsumer<HTMLElement>.iframe(sandbox : IframeSandbox? = null, classes : String? = null, block : IFRAME.() -> Unit = {}) : HTMLElement = IFRAME(attributesMapOf("sandbox", sandbox?.enumEncode(),"class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.img(alt : String? = null, src : String? = null, classes : String? = null, block : IMG.() -> Unit = {}) : HTMLImageElement = IMG(attributesMapOf("alt", alt,"src", src,"class", classes), this).visitAndFinalize(this, block) as HTMLImageElement

public fun TagConsumer<HTMLElement>.input(type : InputType? = null, formEncType : InputFormEncType? = null, formMethod : InputFormMethod? = null, name : String? = null, classes : String? = null, block : INPUT.() -> Unit = {}) : HTMLInputElement = INPUT(attributesMapOf("type", type?.enumEncode(),"formenctype", formEncType?.enumEncode(),"formmethod", formMethod?.enumEncode(),"name", name,"class", classes), this).visitAndFinalize(this, block) as HTMLInputElement

public fun TagConsumer<HTMLElement>.ins(classes : String? = null, block : INS.() -> Unit = {}) : HTMLElement = INS(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.kbd(classes : String? = null, block : KBD.() -> Unit = {}) : HTMLElement = KBD(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.keyGen(keyType : KeyGenKeyType? = null, classes : String? = null, block : KEYGEN.() -> Unit = {}) : HTMLElement = KEYGEN(attributesMapOf("keytype", keyType?.enumEncode(),"class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.label(classes : String? = null, block : LABEL.() -> Unit = {}) : HTMLLabelElement = LABEL(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLLabelElement

public fun TagConsumer<HTMLElement>.legend(classes : String? = null, block : LEGEND.() -> Unit = {}) : HTMLLegendElement = LEGEND(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLLegendElement

public fun TagConsumer<HTMLElement>.li(classes : String? = null, block : LI.() -> Unit = {}) : HTMLLIElement = LI(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLLIElement

public fun TagConsumer<HTMLElement>.link(href : String? = null, rel : String? = null, type : String? = null, block : LINK.() -> Unit = {}) : HTMLLinkElement = LINK(attributesMapOf("href", href,"rel", rel,"type", type), this).visitAndFinalize(this, block) as HTMLLinkElement

public fun TagConsumer<HTMLElement>.map(name : String? = null, classes : String? = null, block : MAP.() -> Unit = {}) : HTMLMapElement = MAP(attributesMapOf("name", name,"class", classes), this).visitAndFinalize(this, block) as HTMLMapElement

public fun TagConsumer<HTMLElement>.mark(classes : String? = null, block : MARK.() -> Unit = {}) : HTMLElement = MARK(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.math(classes : String? = null, block : MATH.() -> Unit = {}) : HTMLElement = MATH(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.mathml(classes : String? = null, content : String = "") : HTMLElement = MATHML(attributesMapOf("class", classes), this).visitAndFinalize(this, {+content})
public fun TagConsumer<HTMLElement>.mathml(classes : String? = null, block : MATHML.() -> Unit = {}) : HTMLElement = MATHML(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.meta(name : String? = null, content : String? = null, block : META.() -> Unit = {}) : HTMLMetaElement = META(attributesMapOf("name", name,"content", content), this).visitAndFinalize(this, block) as HTMLMetaElement

public fun TagConsumer<HTMLElement>.meter(classes : String? = null, block : METER.() -> Unit = {}) : HTMLMeterElement = METER(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLMeterElement

public fun TagConsumer<HTMLElement>.nav(classes : String? = null, block : NAV.() -> Unit = {}) : HTMLElement = NAV(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.noScript(classes : String? = null, block : NOSCRIPT.() -> Unit = {}) : HTMLElement = NOSCRIPT(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.object_(classes : String? = null, block : OBJECT_.() -> Unit = {}) : HTMLElement = OBJECT_(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.ol(classes : String? = null, block : OL.() -> Unit = {}) : HTMLElement = OL(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.optGroup(label : String? = null, classes : String? = null, block : OPTGROUP.() -> Unit = {}) : HTMLOptGroupElement = OPTGROUP(attributesMapOf("label", label,"class", classes), this).visitAndFinalize(this, block) as HTMLOptGroupElement

public fun TagConsumer<HTMLElement>.option(classes : String? = null, content : String = "") : HTMLOptionElement = OPTION(attributesMapOf("class", classes), this).visitAndFinalize(this, {+content}) as HTMLOptionElement
public fun TagConsumer<HTMLElement>.option(classes : String? = null, block : OPTION.() -> Unit = {}) : HTMLOptionElement = OPTION(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLOptionElement

public fun TagConsumer<HTMLElement>.output(classes : String? = null, block : OUTPUT.() -> Unit = {}) : HTMLOutputElement = OUTPUT(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLOutputElement

public fun TagConsumer<HTMLElement>.p(classes : String? = null, block : P.() -> Unit = {}) : HTMLParagraphElement = P(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLParagraphElement

public fun TagConsumer<HTMLElement>.param(name : String? = null, value : String? = null, block : PARAM.() -> Unit = {}) : HTMLParamElement = PARAM(attributesMapOf("name", name,"value", value), this).visitAndFinalize(this, block) as HTMLParamElement

public fun TagConsumer<HTMLElement>.pre(classes : String? = null, block : PRE.() -> Unit = {}) : HTMLPreElement = PRE(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLPreElement

public fun TagConsumer<HTMLElement>.progress(classes : String? = null, block : PROGRESS.() -> Unit = {}) : HTMLProgressElement = PROGRESS(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLProgressElement

public fun TagConsumer<HTMLElement>.q(classes : String? = null, block : Q.() -> Unit = {}) : HTMLElement = Q(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.rp(classes : String? = null, block : RP.() -> Unit = {}) : HTMLElement = RP(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.rt(classes : String? = null, block : RT.() -> Unit = {}) : HTMLElement = RT(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.ruby(classes : String? = null, block : RUBY.() -> Unit = {}) : HTMLElement = RUBY(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.samp(classes : String? = null, block : SAMP.() -> Unit = {}) : HTMLElement = SAMP(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.script(type : String? = null, src : String? = null, block : SCRIPT.() -> Unit = {}) : HTMLScriptElement = SCRIPT(attributesMapOf("type", type,"src", src), this).visitAndFinalize(this, block) as HTMLScriptElement

public fun TagConsumer<HTMLElement>.section(classes : String? = null, block : SECTION.() -> Unit = {}) : HTMLElement = SECTION(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.select(classes : String? = null, block : SELECT.() -> Unit = {}) : HTMLSelectElement = SELECT(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLSelectElement

public fun TagConsumer<HTMLElement>.small(classes : String? = null, block : SMALL.() -> Unit = {}) : HTMLElement = SMALL(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.source(classes : String? = null, block : SOURCE.() -> Unit = {}) : HTMLSourceElement = SOURCE(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLSourceElement

public fun TagConsumer<HTMLElement>.span(classes : String? = null, block : SPAN.() -> Unit = {}) : HTMLSpanElement = SPAN(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLSpanElement

public fun TagConsumer<HTMLElement>.strong(classes : String? = null, block : STRONG.() -> Unit = {}) : HTMLElement = STRONG(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.style(type : String? = null, content : String = "") : HTMLStyleElement = STYLE(attributesMapOf("type", type), this).visitAndFinalize(this, {+content}) as HTMLStyleElement
public fun TagConsumer<HTMLElement>.style(type : String? = null, block : STYLE.() -> Unit = {}) : HTMLStyleElement = STYLE(attributesMapOf("type", type), this).visitAndFinalize(this, block) as HTMLStyleElement

public fun TagConsumer<HTMLElement>.sub(classes : String? = null, block : SUB.() -> Unit = {}) : HTMLElement = SUB(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.sup(classes : String? = null, block : SUP.() -> Unit = {}) : HTMLElement = SUP(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.svg(classes : String? = null, content : String = "") : HTMLElement = SVG(attributesMapOf("class", classes), this).visitAndFinalize(this, {+content})
public fun TagConsumer<HTMLElement>.svg(classes : String? = null, block : SVG.() -> Unit = {}) : HTMLElement = SVG(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.table(classes : String? = null, block : TABLE.() -> Unit = {}) : HTMLTableElement = TABLE(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLTableElement

public fun TagConsumer<HTMLElement>.tbody(classes : String? = null, block : TBODY.() -> Unit = {}) : HTMLTableSectionElement = TBODY(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLTableSectionElement

public fun TagConsumer<HTMLElement>.td(classes : String? = null, block : TD.() -> Unit = {}) : HTMLTableCellElement = TD(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLTableCellElement

public fun TagConsumer<HTMLElement>.textArea(rows : String? = null, cols : String? = null, wrap : TextAreaWrap? = null, classes : String? = null, content : String = "") : HTMLTextAreaElement = TEXTAREA(attributesMapOf("rows", rows,"cols", cols,"wrap", wrap?.enumEncode(),"class", classes), this).visitAndFinalize(this, {+content}) as HTMLTextAreaElement
public fun TagConsumer<HTMLElement>.textArea(rows : String? = null, cols : String? = null, wrap : TextAreaWrap? = null, classes : String? = null, block : TEXTAREA.() -> Unit = {}) : HTMLTextAreaElement = TEXTAREA(attributesMapOf("rows", rows,"cols", cols,"wrap", wrap?.enumEncode(),"class", classes), this).visitAndFinalize(this, block) as HTMLTextAreaElement

public fun TagConsumer<HTMLElement>.tfoot(classes : String? = null, block : TFOOT.() -> Unit = {}) : HTMLTableSectionElement = TFOOT(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLTableSectionElement

public fun TagConsumer<HTMLElement>.th(scope : ThScope? = null, classes : String? = null, block : TH.() -> Unit = {}) : HTMLTableColElement = TH(attributesMapOf("scope", scope?.enumEncode(),"class", classes), this).visitAndFinalize(this, block) as HTMLTableColElement

public fun TagConsumer<HTMLElement>.thead(classes : String? = null, block : THEAD.() -> Unit = {}) : HTMLTableSectionElement = THEAD(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLTableSectionElement

public fun TagConsumer<HTMLElement>.time(classes : String? = null, block : TIME.() -> Unit = {}) : HTMLTimeElement = TIME(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLTimeElement

public fun TagConsumer<HTMLElement>.title(content : String = "") : HTMLTitleElement = TITLE(emptyMap, this).visitAndFinalize(this, {+content}) as HTMLTitleElement
public fun TagConsumer<HTMLElement>.title(block : TITLE.() -> Unit = {}) : HTMLTitleElement = TITLE(emptyMap, this).visitAndFinalize(this, block) as HTMLTitleElement

public fun TagConsumer<HTMLElement>.tr(classes : String? = null, block : TR.() -> Unit = {}) : HTMLTableRowElement = TR(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLTableRowElement

public fun TagConsumer<HTMLElement>.ul(classes : String? = null, block : UL.() -> Unit = {}) : HTMLElement = UL(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.var_(classes : String? = null, block : VAR_.() -> Unit = {}) : HTMLElement = VAR_(attributesMapOf("class", classes), this).visitAndFinalize(this, block)

public fun TagConsumer<HTMLElement>.video(classes : String? = null, block : VIDEO.() -> Unit = {}) : HTMLVideoElement = VIDEO(attributesMapOf("class", classes), this).visitAndFinalize(this, block) as HTMLVideoElement

