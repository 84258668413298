package kotlinx.html

import kotlinx.html.*
import kotlinx.html.impl.*
import kotlinx.html.attributes.*

/*******************************************************************************
    DO NOT EDIT
    This file was generated by module generate
*******************************************************************************/

@Suppress("unused")
open class TABLE(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("table", consumer, initialAttributes, null, false, false), HtmlBlockTag {
    var summary : String
        get()  = attributeStringString.get(this, "summary")
        set(newValue) {attributeStringString.set(this, "summary", newValue)}


}
fun TABLE.caption(classes : String? = null, block : CAPTION.() -> Unit = {}) : Unit = CAPTION(attributesMapOf("class", classes), consumer).visit(block)

fun TABLE.colGroup(classes : String? = null, block : COLGROUP.() -> Unit = {}) : Unit = COLGROUP(attributesMapOf("class", classes), consumer).visit(block)

fun TABLE.thead(classes : String? = null, block : THEAD.() -> Unit = {}) : Unit = THEAD(attributesMapOf("class", classes), consumer).visit(block)

fun TABLE.tfoot(classes : String? = null, block : TFOOT.() -> Unit = {}) : Unit = TFOOT(attributesMapOf("class", classes), consumer).visit(block)

fun TABLE.tbody(classes : String? = null, block : TBODY.() -> Unit = {}) : Unit = TBODY(attributesMapOf("class", classes), consumer).visit(block)

fun TABLE.tr(classes : String? = null, block : TR.() -> Unit = {}) : Unit = TR(attributesMapOf("class", classes), consumer).visit(block)


@Suppress("unused")
open class TBODY(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("tbody", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacade {

}
fun TBODY.tr(classes : String? = null, block : TR.() -> Unit = {}) : Unit = TR(attributesMapOf("class", classes), consumer).visit(block)


@Suppress("unused")
open class TD(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("td", consumer, initialAttributes, null, false, false), HtmlBlockTag {
    var headers : String
        get()  = attributeStringString.get(this, "headers")
        set(newValue) {attributeStringString.set(this, "headers", newValue)}

    var rowSpan : String
        get()  = attributeStringString.get(this, "rowspan")
        set(newValue) {attributeStringString.set(this, "rowspan", newValue)}

    var colSpan : String
        get()  = attributeStringString.get(this, "colspan")
        set(newValue) {attributeStringString.set(this, "colspan", newValue)}


}

@Suppress("unused")
open class TEXTAREA(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("textarea", consumer, initialAttributes, null, true, false), CommonAttributeGroupFacadeFlowInteractivePhrasingContent {
    var autoFocus : Boolean
        get()  = attributeBooleanTicker.get(this, "autofocus")
        set(newValue) {attributeBooleanTicker.set(this, "autofocus", newValue)}

    var disabled : Boolean
        get()  = attributeBooleanTicker.get(this, "disabled")
        set(newValue) {attributeBooleanTicker.set(this, "disabled", newValue)}

    var form : String
        get()  = attributeStringString.get(this, "form")
        set(newValue) {attributeStringString.set(this, "form", newValue)}

    var maxLength : String
        get()  = attributeStringString.get(this, "maxlength")
        set(newValue) {attributeStringString.set(this, "maxlength", newValue)}

    var minLength : String
        get()  = attributeStringString.get(this, "minlength")
        set(newValue) {attributeStringString.set(this, "minlength", newValue)}

    var name : String
        get()  = attributeStringString.get(this, "name")
        set(newValue) {attributeStringString.set(this, "name", newValue)}

    var placeholder : String
        get()  = attributeStringString.get(this, "placeholder")
        set(newValue) {attributeStringString.set(this, "placeholder", newValue)}

    var readonly : Boolean
        get()  = attributeBooleanTicker.get(this, "readonly")
        set(newValue) {attributeBooleanTicker.set(this, "readonly", newValue)}

    var required : Boolean
        get()  = attributeBooleanTicker.get(this, "required")
        set(newValue) {attributeBooleanTicker.set(this, "required", newValue)}

    var rows : String
        get()  = attributeStringString.get(this, "rows")
        set(newValue) {attributeStringString.set(this, "rows", newValue)}

    var cols : String
        get()  = attributeStringString.get(this, "cols")
        set(newValue) {attributeStringString.set(this, "cols", newValue)}

    var wrap : TextAreaWrap
        get()  = attributeTextAreaWrapEnumTextAreaWrapValues.get(this, "wrap")
        set(newValue) {attributeTextAreaWrapEnumTextAreaWrapValues.set(this, "wrap", newValue)}


}
val TEXTAREA.asFlowContent : FlowContent
    get()  = this

val TEXTAREA.asInteractiveContent : InteractiveContent
    get()  = this

val TEXTAREA.asPhrasingContent : PhrasingContent
    get()  = this


@Suppress("unused")
open class TFOOT(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("tfoot", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacade {

}
fun TFOOT.tr(classes : String? = null, block : TR.() -> Unit = {}) : Unit = TR(attributesMapOf("class", classes), consumer).visit(block)


@Suppress("unused")
open class TH(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("th", consumer, initialAttributes, null, false, false), HtmlInlineTag {
    var headers : String
        get()  = attributeStringString.get(this, "headers")
        set(newValue) {attributeStringString.set(this, "headers", newValue)}

    var rowSpan : String
        get()  = attributeStringString.get(this, "rowspan")
        set(newValue) {attributeStringString.set(this, "rowspan", newValue)}

    var colSpan : String
        get()  = attributeStringString.get(this, "colspan")
        set(newValue) {attributeStringString.set(this, "colspan", newValue)}

    var scope : ThScope
        get()  = attributeThScopeEnumThScopeValues.get(this, "scope")
        set(newValue) {attributeThScopeEnumThScopeValues.set(this, "scope", newValue)}


}

@Suppress("unused")
open class THEAD(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("thead", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacade {

}
fun THEAD.tr(classes : String? = null, block : TR.() -> Unit = {}) : Unit = TR(attributesMapOf("class", classes), consumer).visit(block)


@Suppress("unused")
open class TIME(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("time", consumer, initialAttributes, null, true, false), HtmlBlockInlineTag {
    var dateTime : String
        get()  = attributeStringString.get(this, "datetime")
        set(newValue) {attributeStringString.set(this, "datetime", newValue)}


}
val TIME.asFlowContent : FlowContent
    get()  = this

val TIME.asPhrasingContent : PhrasingContent
    get()  = this


@Suppress("unused")
open class TITLE(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("title", consumer, initialAttributes, null, false, false), HtmlHeadTag {

}

@Suppress("unused")
open class TR(initialAttributes : Map<String, String>, override val consumer : TagConsumer<*>) : HTMLTag("tr", consumer, initialAttributes, null, false, false), CommonAttributeGroupFacade {

}
fun TR.th(scope : ThScope? = null, classes : String? = null, block : TH.() -> Unit = {}) : Unit = TH(attributesMapOf("scope", scope?.enumEncode(),"class", classes), consumer).visit(block)
fun TR.colTh(classes : String? = null, block : TH.() -> Unit = {}) : Unit = TH(attributesMapOf("scope", ThScope.col.realValue,"class", classes), consumer).visit(block)
fun TR.colGroupTh(classes : String? = null, block : TH.() -> Unit = {}) : Unit = TH(attributesMapOf("scope", ThScope.colGroup.realValue,"class", classes), consumer).visit(block)
fun TR.rowTh(classes : String? = null, block : TH.() -> Unit = {}) : Unit = TH(attributesMapOf("scope", ThScope.row.realValue,"class", classes), consumer).visit(block)
fun TR.rowGroupTh(classes : String? = null, block : TH.() -> Unit = {}) : Unit = TH(attributesMapOf("scope", ThScope.rowGroup.realValue,"class", classes), consumer).visit(block)

fun TR.td(classes : String? = null, block : TD.() -> Unit = {}) : Unit = TD(attributesMapOf("class", classes), consumer).visit(block)


