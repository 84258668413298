package kotlinx.html

import kotlinx.html.*
import kotlinx.html.impl.*

/*******************************************************************************
    DO NOT EDIT
    This file was generated by module generate
*******************************************************************************/

interface CommonAttributeGroupFacade : Tag {
}
var CommonAttributeGroupFacade.enableTheming : Boolean
    get()  = attributeBooleanBoolean.get(this, "EnableTheming")
    set(newValue) {attributeBooleanBoolean.set(this, "EnableTheming", newValue)}

var CommonAttributeGroupFacade.enableViewState : Boolean
    get()  = attributeBooleanBoolean.get(this, "EnableViewState")
    set(newValue) {attributeBooleanBoolean.set(this, "EnableViewState", newValue)}

var CommonAttributeGroupFacade.skinID : String
    get()  = attributeStringString.get(this, "SkinID")
    set(newValue) {attributeStringString.set(this, "SkinID", newValue)}

var CommonAttributeGroupFacade.visible : Boolean
    get()  = attributeBooleanBoolean.get(this, "Visible")
    set(newValue) {attributeBooleanBoolean.set(this, "Visible", newValue)}

var CommonAttributeGroupFacade.accessKey : String
    get()  = attributeStringString.get(this, "accesskey")
    set(newValue) {attributeStringString.set(this, "accesskey", newValue)}

var CommonAttributeGroupFacade.classes : Set<String>
    get()  = attributeSetStringStringSet.get(this, "class")
    set(newValue) {attributeSetStringStringSet.set(this, "class", newValue)}

var CommonAttributeGroupFacade.contentEditable : Boolean
    get()  = attributeBooleanBoolean.get(this, "contenteditable")
    set(newValue) {attributeBooleanBoolean.set(this, "contenteditable", newValue)}

var CommonAttributeGroupFacade.contextMenu : String
    get()  = attributeStringString.get(this, "contextmenu")
    set(newValue) {attributeStringString.set(this, "contextmenu", newValue)}

var CommonAttributeGroupFacade.dataFolderName : String
    get()  = attributeStringString.get(this, "data-FolderName")
    set(newValue) {attributeStringString.set(this, "data-FolderName", newValue)}

var CommonAttributeGroupFacade.dataMsgId : String
    get()  = attributeStringString.get(this, "data-MsgId")
    set(newValue) {attributeStringString.set(this, "data-MsgId", newValue)}

var CommonAttributeGroupFacade.dir : Dir
    get()  = attributeDirEnumDirValues.get(this, "dir")
    set(newValue) {attributeDirEnumDirValues.set(this, "dir", newValue)}

var CommonAttributeGroupFacade.draggable : Draggable
    get()  = attributeDraggableEnumDraggableValues.get(this, "draggable")
    set(newValue) {attributeDraggableEnumDraggableValues.set(this, "draggable", newValue)}

var CommonAttributeGroupFacade.hidden : Boolean
    get()  = attributeBooleanTicker.get(this, "hidden")
    set(newValue) {attributeBooleanTicker.set(this, "hidden", newValue)}

var CommonAttributeGroupFacade.id : String
    get()  = attributeStringString.get(this, "id")
    set(newValue) {attributeStringString.set(this, "id", newValue)}

var CommonAttributeGroupFacade.itemProp : String
    get()  = attributeStringString.get(this, "itemprop")
    set(newValue) {attributeStringString.set(this, "itemprop", newValue)}

var CommonAttributeGroupFacade.lang : String
    get()  = attributeStringString.get(this, "lang")
    set(newValue) {attributeStringString.set(this, "lang", newValue)}

var CommonAttributeGroupFacade.onAbort : String
    get()  = attributeStringString.get(this, "onabort")
    set(newValue) {attributeStringString.set(this, "onabort", newValue)}

var CommonAttributeGroupFacade.onBlur : String
    get()  = attributeStringString.get(this, "onblur")
    set(newValue) {attributeStringString.set(this, "onblur", newValue)}

var CommonAttributeGroupFacade.onCanPlay : String
    get()  = attributeStringString.get(this, "oncanplay")
    set(newValue) {attributeStringString.set(this, "oncanplay", newValue)}

var CommonAttributeGroupFacade.onCanPlayThrough : String
    get()  = attributeStringString.get(this, "oncanplaythrough")
    set(newValue) {attributeStringString.set(this, "oncanplaythrough", newValue)}

var CommonAttributeGroupFacade.onChange : String
    get()  = attributeStringString.get(this, "onchange")
    set(newValue) {attributeStringString.set(this, "onchange", newValue)}

var CommonAttributeGroupFacade.onClick : String
    get()  = attributeStringString.get(this, "onclick")
    set(newValue) {attributeStringString.set(this, "onclick", newValue)}

var CommonAttributeGroupFacade.onContextMenu : String
    get()  = attributeStringString.get(this, "oncontextmenu")
    set(newValue) {attributeStringString.set(this, "oncontextmenu", newValue)}

var CommonAttributeGroupFacade.onDoubleClick : String
    get()  = attributeStringString.get(this, "ondblclick")
    set(newValue) {attributeStringString.set(this, "ondblclick", newValue)}

var CommonAttributeGroupFacade.onDrag : String
    get()  = attributeStringString.get(this, "ondrag")
    set(newValue) {attributeStringString.set(this, "ondrag", newValue)}

var CommonAttributeGroupFacade.onDragEnd : String
    get()  = attributeStringString.get(this, "ondragend")
    set(newValue) {attributeStringString.set(this, "ondragend", newValue)}

var CommonAttributeGroupFacade.onDragEnter : String
    get()  = attributeStringString.get(this, "ondragenter")
    set(newValue) {attributeStringString.set(this, "ondragenter", newValue)}

var CommonAttributeGroupFacade.onDragLeave : String
    get()  = attributeStringString.get(this, "ondragleave")
    set(newValue) {attributeStringString.set(this, "ondragleave", newValue)}

var CommonAttributeGroupFacade.onDragOver : String
    get()  = attributeStringString.get(this, "ondragover")
    set(newValue) {attributeStringString.set(this, "ondragover", newValue)}

var CommonAttributeGroupFacade.onDragStart : String
    get()  = attributeStringString.get(this, "ondragstart")
    set(newValue) {attributeStringString.set(this, "ondragstart", newValue)}

var CommonAttributeGroupFacade.onDrop : String
    get()  = attributeStringString.get(this, "ondrop")
    set(newValue) {attributeStringString.set(this, "ondrop", newValue)}

var CommonAttributeGroupFacade.onDurationChange : String
    get()  = attributeStringString.get(this, "ondurationchange")
    set(newValue) {attributeStringString.set(this, "ondurationchange", newValue)}

var CommonAttributeGroupFacade.onEmptied : String
    get()  = attributeStringString.get(this, "onemptied")
    set(newValue) {attributeStringString.set(this, "onemptied", newValue)}

var CommonAttributeGroupFacade.onEnded : String
    get()  = attributeStringString.get(this, "onended")
    set(newValue) {attributeStringString.set(this, "onended", newValue)}

var CommonAttributeGroupFacade.onError : String
    get()  = attributeStringString.get(this, "onerror")
    set(newValue) {attributeStringString.set(this, "onerror", newValue)}

var CommonAttributeGroupFacade.onFocus : String
    get()  = attributeStringString.get(this, "onfocus")
    set(newValue) {attributeStringString.set(this, "onfocus", newValue)}

var CommonAttributeGroupFacade.onFormChange : String
    get()  = attributeStringString.get(this, "onformchange")
    set(newValue) {attributeStringString.set(this, "onformchange", newValue)}

var CommonAttributeGroupFacade.onFormInput : String
    get()  = attributeStringString.get(this, "onforminput")
    set(newValue) {attributeStringString.set(this, "onforminput", newValue)}

var CommonAttributeGroupFacade.onInput : String
    get()  = attributeStringString.get(this, "oninput")
    set(newValue) {attributeStringString.set(this, "oninput", newValue)}

var CommonAttributeGroupFacade.onInvalid : String
    get()  = attributeStringString.get(this, "oninvalid")
    set(newValue) {attributeStringString.set(this, "oninvalid", newValue)}

var CommonAttributeGroupFacade.onKeyDown : String
    get()  = attributeStringString.get(this, "onkeydown")
    set(newValue) {attributeStringString.set(this, "onkeydown", newValue)}

var CommonAttributeGroupFacade.onKeyPress : String
    get()  = attributeStringString.get(this, "onkeypress")
    set(newValue) {attributeStringString.set(this, "onkeypress", newValue)}

var CommonAttributeGroupFacade.onKeyUp : String
    get()  = attributeStringString.get(this, "onkeyup")
    set(newValue) {attributeStringString.set(this, "onkeyup", newValue)}

var CommonAttributeGroupFacade.onLoad : String
    get()  = attributeStringString.get(this, "onload")
    set(newValue) {attributeStringString.set(this, "onload", newValue)}

var CommonAttributeGroupFacade.onLoadedData : String
    get()  = attributeStringString.get(this, "onloadeddata")
    set(newValue) {attributeStringString.set(this, "onloadeddata", newValue)}

var CommonAttributeGroupFacade.onLoadedMetaData : String
    get()  = attributeStringString.get(this, "onloadedmetadata")
    set(newValue) {attributeStringString.set(this, "onloadedmetadata", newValue)}

var CommonAttributeGroupFacade.onLoadStart : String
    get()  = attributeStringString.get(this, "onloadstart")
    set(newValue) {attributeStringString.set(this, "onloadstart", newValue)}

var CommonAttributeGroupFacade.onMouseDown : String
    get()  = attributeStringString.get(this, "onmousedown")
    set(newValue) {attributeStringString.set(this, "onmousedown", newValue)}

var CommonAttributeGroupFacade.onMouseMove : String
    get()  = attributeStringString.get(this, "onmousemove")
    set(newValue) {attributeStringString.set(this, "onmousemove", newValue)}

var CommonAttributeGroupFacade.onMouseOut : String
    get()  = attributeStringString.get(this, "onmouseout")
    set(newValue) {attributeStringString.set(this, "onmouseout", newValue)}

var CommonAttributeGroupFacade.onMouseOver : String
    get()  = attributeStringString.get(this, "onmouseover")
    set(newValue) {attributeStringString.set(this, "onmouseover", newValue)}

var CommonAttributeGroupFacade.onMouseUp : String
    get()  = attributeStringString.get(this, "onmouseup")
    set(newValue) {attributeStringString.set(this, "onmouseup", newValue)}

var CommonAttributeGroupFacade.onMouseWheel : String
    get()  = attributeStringString.get(this, "onmousewheel")
    set(newValue) {attributeStringString.set(this, "onmousewheel", newValue)}

var CommonAttributeGroupFacade.onPause : String
    get()  = attributeStringString.get(this, "onpause")
    set(newValue) {attributeStringString.set(this, "onpause", newValue)}

var CommonAttributeGroupFacade.onPlay : String
    get()  = attributeStringString.get(this, "onplay")
    set(newValue) {attributeStringString.set(this, "onplay", newValue)}

var CommonAttributeGroupFacade.onPlaying : String
    get()  = attributeStringString.get(this, "onplaying")
    set(newValue) {attributeStringString.set(this, "onplaying", newValue)}

var CommonAttributeGroupFacade.onProgress : String
    get()  = attributeStringString.get(this, "onprogress")
    set(newValue) {attributeStringString.set(this, "onprogress", newValue)}

var CommonAttributeGroupFacade.onRateChange : String
    get()  = attributeStringString.get(this, "onratechange")
    set(newValue) {attributeStringString.set(this, "onratechange", newValue)}

var CommonAttributeGroupFacade.onReadyStateChange : String
    get()  = attributeStringString.get(this, "onreadystatechange")
    set(newValue) {attributeStringString.set(this, "onreadystatechange", newValue)}

var CommonAttributeGroupFacade.onScroll : String
    get()  = attributeStringString.get(this, "onscroll")
    set(newValue) {attributeStringString.set(this, "onscroll", newValue)}

var CommonAttributeGroupFacade.onSearch : String
    get()  = attributeStringString.get(this, "onsearch")
    set(newValue) {attributeStringString.set(this, "onsearch", newValue)}

var CommonAttributeGroupFacade.onSeeked : String
    get()  = attributeStringString.get(this, "onseeked")
    set(newValue) {attributeStringString.set(this, "onseeked", newValue)}

var CommonAttributeGroupFacade.onSeeking : String
    get()  = attributeStringString.get(this, "onseeking")
    set(newValue) {attributeStringString.set(this, "onseeking", newValue)}

var CommonAttributeGroupFacade.onSelect : String
    get()  = attributeStringString.get(this, "onselect")
    set(newValue) {attributeStringString.set(this, "onselect", newValue)}

var CommonAttributeGroupFacade.onShow : String
    get()  = attributeStringString.get(this, "onshow")
    set(newValue) {attributeStringString.set(this, "onshow", newValue)}

var CommonAttributeGroupFacade.onStalled : String
    get()  = attributeStringString.get(this, "onstalled")
    set(newValue) {attributeStringString.set(this, "onstalled", newValue)}

var CommonAttributeGroupFacade.onSubmit : String
    get()  = attributeStringString.get(this, "onsubmit")
    set(newValue) {attributeStringString.set(this, "onsubmit", newValue)}

var CommonAttributeGroupFacade.onSuspend : String
    get()  = attributeStringString.get(this, "onsuspend")
    set(newValue) {attributeStringString.set(this, "onsuspend", newValue)}

var CommonAttributeGroupFacade.onTimeUpdate : String
    get()  = attributeStringString.get(this, "ontimeupdate")
    set(newValue) {attributeStringString.set(this, "ontimeupdate", newValue)}

var CommonAttributeGroupFacade.onVolumeChange : String
    get()  = attributeStringString.get(this, "onvolumechange")
    set(newValue) {attributeStringString.set(this, "onvolumechange", newValue)}

var CommonAttributeGroupFacade.onWaiting : String
    get()  = attributeStringString.get(this, "onwaiting")
    set(newValue) {attributeStringString.set(this, "onwaiting", newValue)}

var CommonAttributeGroupFacade.role : String
    get()  = attributeStringString.get(this, "role")
    set(newValue) {attributeStringString.set(this, "role", newValue)}

var CommonAttributeGroupFacade.runAt : RunAt
    get()  = attributeRunAtEnumRunAtValues.get(this, "runat")
    set(newValue) {attributeRunAtEnumRunAtValues.set(this, "runat", newValue)}

var CommonAttributeGroupFacade.spellCheck : Boolean
    get()  = attributeBooleanBoolean.get(this, "spellcheck")
    set(newValue) {attributeBooleanBoolean.set(this, "spellcheck", newValue)}

var CommonAttributeGroupFacade.style : String
    get()  = attributeStringString.get(this, "style")
    set(newValue) {attributeStringString.set(this, "style", newValue)}

var CommonAttributeGroupFacade.subject : String
    get()  = attributeStringString.get(this, "subject")
    set(newValue) {attributeStringString.set(this, "subject", newValue)}

var CommonAttributeGroupFacade.tabIndex : String
    get()  = attributeStringString.get(this, "tabIndex")
    set(newValue) {attributeStringString.set(this, "tabIndex", newValue)}

var CommonAttributeGroupFacade.title : String
    get()  = attributeStringString.get(this, "title")
    set(newValue) {attributeStringString.set(this, "title", newValue)}


interface FormServerAttributeGroupFacade : Tag {
}
var FormServerAttributeGroupFacade.defaultButton : String
    get()  = attributeStringString.get(this, "DefaultButton")
    set(newValue) {attributeStringString.set(this, "DefaultButton", newValue)}

var FormServerAttributeGroupFacade.defaultFocus : String
    get()  = attributeStringString.get(this, "DefaultFocus")
    set(newValue) {attributeStringString.set(this, "DefaultFocus", newValue)}

var FormServerAttributeGroupFacade.submitDisabledControls : Boolean
    get()  = attributeBooleanBoolean.get(this, "SubmitDisabledControls")
    set(newValue) {attributeBooleanBoolean.set(this, "SubmitDisabledControls", newValue)}


interface InputServerAttributeGroupFacade : Tag {
}
var InputServerAttributeGroupFacade.causesValidation : Boolean
    get()  = attributeBooleanBoolean.get(this, "CausesValidation")
    set(newValue) {attributeBooleanBoolean.set(this, "CausesValidation", newValue)}

var InputServerAttributeGroupFacade.validationGroup : String
    get()  = attributeStringString.get(this, "ValidationGroup")
    set(newValue) {attributeStringString.set(this, "ValidationGroup", newValue)}


interface SelectServerAttributeGroupFacade : Tag {
}
var SelectServerAttributeGroupFacade.dataSourceID : String
    get()  = attributeStringString.get(this, "DataSourceID")
    set(newValue) {attributeStringString.set(this, "DataSourceID", newValue)}

var SelectServerAttributeGroupFacade.dataTextField : String
    get()  = attributeStringString.get(this, "DataTextField")
    set(newValue) {attributeStringString.set(this, "DataTextField", newValue)}

var SelectServerAttributeGroupFacade.dataValueField : String
    get()  = attributeStringString.get(this, "DataValueField")
    set(newValue) {attributeStringString.set(this, "DataValueField", newValue)}


